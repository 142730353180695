import SEO from '../../components/seo'
import React from 'react'
import Layout from '../../components/layout'
import HeaderImg from '../../components/HeaderImg/HeaderImg'

import { JavascriptIcon } from '../../components/icons/JavascriptIcon'
import { TypescriptIcon } from '../../components/icons/TypescriptIcon'
import { HtmlIcon } from '../../components/icons/HtmlIcon'
import { ReactIcon } from '../../components/icons/ReactIcon'
import { DocuSignIcon } from '../../components/icons/DocuSignIcon'
import ProjectOverview from '../../components/ProjectOverview/ProjectOverview'
import Testimonial from '../../components/Testimonial/Testimonial'
import TextBlock from '../../components/TextBlock/TextBlock'
import Goals from '../../components/Goals/Goals'
import Outcomes from '../../components/Outcomes/Outcomes'

import MiracleDentalCareLogo from '../../images/miracle-dental-logo.png';
import OpenDentalLogo from '../../images/open-dental-logo-1.png'
import { StrapiIcon } from '../../components/icons/StrapiIcon'
import Slider from '../../components/Slider/Slider'
import BottomNav from '../../components/BottomNav/BottomNav'

const MiracleDentalCarePage = () => {
  const services = [
    {
      id: 1,
      text: 'Custom Application Development',
    },
    {
      id: 2,
      text: 'Project management',
    },
    {
      id: 3,
      text: '3 people team',
    },
    {
      id: 4,
      text: 'Mobile-Optimized Website Design',
    },
    {
      id: 5,
      text: '3rd party API Integration',
    },
    {
      id: 6,
      text: 'CRM Development',
    },
  ]

  const techs = [
    {
      id: 1111,
      icon: JavascriptIcon(),
    },
    {
      id: 6666,
      icon: TypescriptIcon(),
    },
    {
      id: 2222,
      icon: ReactIcon(),
    },
    {
      id: 4444,
      icon: HtmlIcon(),
    },
    {
      id: 5555,
      icon: DocuSignIcon(),
    },
    {
      id: 5555,
      icon: <img src={OpenDentalLogo} alt="Open Dental Logo" />,
    },
    {
      id: 66644,
      icon: StrapiIcon(),
    }
  ]

  const goals = [
    {
      id: 11,
      text:
        'Digitizing the consent form process, facilitating more natural acquisition and less manual data-input work',
    },
    {
      id: 22,
      text:
        'Creating a centralized CRM system to efficiently manage and organize patient data',
    },
    {
      id: 33,
      text: 'Integrating the newly built CRM with existing OpenDental software for real-time patient data accuracy',
    },
    {
      id: 44,
      text: 'Ensuring total compliance with HIPAA standards',
    },
  ]

  const slides = [
    {
      id: 1,
      img:
        'https://cdn.emersoft.co/emersoft-website/miracle-dental-care/office-1.png',
      alt: 'Miracle Dental Care office',
    },
    {
      id: 2,
      img:
        'https://cdn.emersoft.co/emersoft-website/miracle-dental-care/office-2.png',
      alt: 'Miracle Dental Care office',
    },
  ]

  const outcomes = [
    {
      id: 111,
      title: 'Countless',
      subtitle: 'Hours saved in patient enrollment',
    },
    {
      id: 222,
      title: 'Significant',
      subtitle: 'Performance enhancements',
    },
    {
      id: 333,
      title: '50%+',
      subtitle: 'Reduction in administrative errors',
    },
  ]

  return (
    <Layout>
      <SEO
        title="Miracle Dental Associates"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `digital agency`,
          `Miracle Dental Associates`,
          `Angular`,
          `Healthcare`,
          `Dentistry`,
        ]}
      />
      <HeaderImg
        header="header-img--miracle-dental-care"
        img={MiracleDentalCareLogo}
      />
      <ProjectOverview
        title="Miracle Dental Associates"
        category='Healthcare'
        link="https://www.miracledentalcare.org/"
        services={services}
        technologies={techs}
        subtitle="Streamlining Patient Onboarding with Miracle Dental Associates"
        paragraph1="Miracle Dental Associates, a dental practice specializing in pediatric dentistry. Known for their innovative approach, the practice operates across multiple schools, simultaneously managing extensive patient data."
      />
      <Testimonial
        paragraph="Working with Emersoft has been a game-changer for us at Miracle Dental Associates. From day one, they were more than just a service provider; they were our partners in revolutionizing our patient management process. You guys have consistently produced our desired outcome!"
        author="Dr. Brittany Kinol"
        authorRole="CEO and Founder of Miracle Dental Associates"
      />
      <TextBlock
        heading="Brief"
        paragraph1="Miracle Dental Associates sought a digital solution to streamline their traditional patient enrollment process."
        paragraph2="They required a system that would conveniently gather parental consent, organize and manage patient data across various locations while adhering to HIPAA compliance standards."
      />
      <Slider slides={slides} />
      <Goals goalsList={goals} />
      <TextBlock
        heading="Our Process"
        paragraph1="We developed an all-inclusive digital solution starting with a mobile-optimized, interactive consent form landing page. Unique URL IDs identified the relevant school to facilitate a smooth process for parents. We provided a DocuSign API-powered consent form to capture digital signatures valid under law."
        paragraph2="Furthermore, we built a custom CRM to securely manage patient data, facilitate the addition of new schools and send customized emails to parents efficiently. The CRM was integrated seamlessly with the existing OpenDental software for regular updates and accurate record-keeping."
      />
      <Outcomes outcomes={outcomes} />
      <BottomNav
        heading="See More"
        firstLink="/our-work/vooba"
        firstHeading="Vooba"
        secondLink="/our-work/total-primary-care"
        secondHeading="Total Primary Care"
      />
    </Layout>
  )
}

export default MiracleDentalCarePage;