import React from 'react'

export const StrapiIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width='166' height='40' viewBox="0 0 166 40">
      <title>Strapi</title>
      <path fill="currentColor"
            d="M159.245 5.87c.676.679 1.475 1.018 2.396 1.018.95 0 1.763-.34 2.439-1.018.677-.679 1.015-1.48 1.015-2.404 0-.925-.338-1.733-1.015-2.426-.676-.693-1.489-1.04-2.439-1.04-.921 0-1.72.347-2.396 1.04-.676.693-1.015 1.501-1.015 2.426 0 .924.339 1.725 1.015 2.404ZM86.411 14.73a.129.129 0 0 1-.128.13h-4.75v9.01c0 .75.187 1.3.56 1.646.375.347.922.542 1.641.585.659.04 1.462.037 2.409-.008l.024-.001.044-.002.065-.004a.129.129 0 0 1 .135.129v4.818c0 .066-.05.12-.114.127l-.087.01c-3.722.398-6.353.03-7.894-1.105-1.569-1.155-2.353-3.22-2.353-6.195v-9.01h-3.627a.129.129 0 0 1-.129-.13V9.617c0-.07.058-.128.129-.128h3.627V5.91c0-.102.06-.194.153-.235l5.236-2.312a.128.128 0 0 1 .18.117v6.008h4.75c.072 0 .13.057.13.128v5.115ZM96.509 13.213c.518-1.386 1.374-2.426 2.569-3.12 1.093-.633 2.301-.977 3.623-1.031.064-.003.161-.005.241-.006a.127.127 0 0 1 .129.128v5.878a.208.208 0 0 1-.229.206c-1.606-.145-3.048.21-4.326 1.064-1.338.895-2.007 2.383-2.007 4.462v10.225a.129.129 0 0 1-.129.129h-5.312a.128.128 0 0 1-.129-.129V9.616c0-.071.058-.129.129-.129h5.312c.071 0 .129.058.129.129v3.597Z" />
      <path fill="currentColor" fillRule="evenodd"
            d="M122.844 9.487a.129.129 0 0 0-.128.129v2.427c-1.669-2.108-4.015-3.162-7.037-3.162-2.879 0-5.347 1.104-7.405 3.314-2.058 2.209-3.086 4.916-3.086 8.122 0 3.206 1.028 5.913 3.086 8.123 2.058 2.21 4.526 3.314 7.405 3.314 3.022 0 5.368-1.054 7.037-3.163v2.428c0 .07.058.128.128.128h5.313a.128.128 0 0 0 .128-.128V9.616a.128.128 0 0 0-.128-.129h-5.313ZM112.44 24.736c1.123 1.127 2.548 1.69 4.274 1.69 1.727 0 3.159-.57 4.296-1.711 1.137-1.141 1.706-2.607 1.706-4.398 0-1.79-.569-3.256-1.706-4.397-1.137-1.14-2.569-1.71-4.296-1.71-1.726 0-3.151.57-4.274 1.71-1.122 1.141-1.684 2.607-1.684 4.397 0 1.791.562 3.264 1.684 4.419ZM152.474 12.195c-2.058-2.21-4.541-3.314-7.448-3.314-3.022 0-5.353 1.054-6.994 3.162V9.616a.128.128 0 0 0-.128-.129h-5.313a.128.128 0 0 0-.128.129v30.067c0 .07.057.128.128.128h5.313a.128.128 0 0 0 .128-.128V28.59c1.641 2.109 3.972 3.163 6.994 3.163 2.907 0 5.39-1.105 7.448-3.314 2.058-2.21 3.087-4.917 3.087-8.123 0-3.206-1.029-5.913-3.087-8.122Zm-12.758 12.541c1.122 1.127 2.547 1.69 4.274 1.69 1.727 0 3.159-.57 4.296-1.711 1.137-1.141 1.705-2.607 1.705-4.398 0-1.79-.568-3.256-1.705-4.397-1.137-1.14-2.569-1.71-4.296-1.71-1.727 0-3.152.57-4.274 1.71-1.123 1.141-1.684 2.607-1.684 4.397 0 1.791.561 3.264 1.684 4.419Z"
            clipRule="evenodd" />
      <path fill="currentColor"
            d="M159.007 31.148a.128.128 0 0 1-.128-.129V9.616c0-.07.057-.128.128-.128h5.313c.071 0 .128.057.128.128v21.403a.128.128 0 0 1-.128.129h-5.313ZM59.592 17.047c-.763-.361-1.144-.83-1.144-1.408 0-.55.237-.982.712-1.3.475-.318 1.073-.476 1.792-.476 1.38 0 2.422.54 3.127 1.619.05.075.145.105.227.069l4.699-2.086a.128.128 0 0 0 .059-.182c-.052-.088-.12-.202-.166-.273-.805-1.27-1.847-2.217-3.153-2.938-1.44-.794-3.037-1.191-4.793-1.191-2.331 0-4.281.613-5.85 1.84-1.569 1.228-2.353 2.91-2.353 5.048 0 1.415.381 2.592 1.144 3.53a6.737 6.737 0 0 0 2.785 2.058c1.094.433 2.187.794 3.281 1.083 1.094.289 2.022.614 2.785.975.763.36 1.144.83 1.144 1.408 0 1.241-.993 1.862-2.979 1.862-1.89 0-3.2-.7-3.93-2.103a.256.256 0 0 0-.33-.12l-4.686 2.083a.128.128 0 0 0-.064.173l.054.11c1.637 3.284 4.623 4.926 8.956 4.926 2.475 0 4.54-.6 6.195-1.798 1.655-1.199 2.483-2.91 2.483-5.133 0-1.473-.382-2.7-1.144-3.683-.763-.982-1.691-1.682-2.785-2.1A28.704 28.704 0 0 0 62.377 18c-1.094-.275-2.022-.593-2.785-.953Z" />
      <path fill="currentColor"
            d="M0 13.867C0 7.33 0 4.06 2.03 2.03 4.062 0 7.33 0 13.868 0h12.266c6.537 0 9.805 0 11.836 2.03C40 4.062 40 7.33 40 13.868v12.266c0 6.537 0 9.805-2.03 11.836C35.937 40 32.67 40 26.132 40H13.867C7.33 40 4.06 40 2.03 37.97 0 35.937 0 32.67 0 26.132V13.867Z" />
      <path fill="currentColor" fillRule="evenodd" d="M27.6 12.133H14.133V19H21v6.867h6.866V12.4a.267.267 0 0 0-.266-.267Z"
            clipRule="evenodd" />
      <path fill="currentColor" d="M21 19h-.268v.267H21V19Z" />
      <path fill="currentColor"
            d="M14.133 19h6.6c.147 0 .267.12.267.267v6.6h-6.6a.267.267 0 0 1-.267-.267V19ZM21 25.867h6.867l-6.64 6.639a.133.133 0 0 1-.227-.094v-6.545Z" />
      <path fill="currentColor" d="M14.133 19H7.59a.133.133 0 0 1-.095-.228l6.64-6.639V19Z" />
    </svg>
  )
}