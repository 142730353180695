import React from 'react'

export const TypescriptIcon = () => {
  return (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <title>Typescript</title>
      <g fillRule="nonzero" fill="none" opacity=".7">
        <path fill="#666" d="M0 20V0h40v40H0" />
        <path
          d="M8.77 20.07v1.63h5.2v14.8h3.69V21.7h5.2v-1.6c0-.9 0-1.63-.04-1.65 0-.03-3.17-.04-7.02-.04l-7 .03v1.64l-.03-.01zm23.37-1.67c1.02.24 1.8.7 2.5 1.43.37.4.92 1.1.96 1.28 0 .06-1.73 1.23-2.78 1.88-.04.03-.2-.14-.36-.4-.52-.74-1.05-1.06-1.88-1.12-1.2-.08-2 .55-2 1.6 0 .32.06.5.18.76.27.55.77.88 2.32 1.56 2.86 1.23 4.1 2.04 4.85 3.2.85 1.3 1.04 3.34.47 4.87-.64 1.67-2.2 2.8-4.43 3.17-.7.12-2.3.1-3.05-.03-1.6-.3-3.13-1.1-4.07-2.13-.37-.4-1.08-1.47-1.04-1.54l.38-.24 1.5-.87 1.13-.66.26.35c.33.52 1.07 1.22 1.5 1.46 1.3.67 3.04.58 3.9-.2.37-.34.53-.7.53-1.2 0-.46-.07-.67-.3-1.02-.32-.44-.96-.8-2.76-1.6-2.07-.88-2.95-1.44-3.77-2.3-.47-.52-.9-1.33-1.1-2-.15-.58-.2-2-.06-2.57.43-2 1.94-3.4 4.1-3.8.7-.14 2.35-.08 3.04.1l-.02.02z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}
